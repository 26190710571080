import { Urls } from '@services/api/base/axios';
import { post } from '@services/api/base';
import { DateTime } from 'luxon';

export default {
  getSystemsCharts: (systems: number[], charts: ChartDTO[], timezone: string = DateTime.now().zoneName || 'UTC') =>
    post<ChartResult[]>(`${Urls.fleet}/charts/systems`, { systems, charts, timezone }),

  getSystemsChartsById: (systems: number[], charts: ChartDTO[], timezone: string = DateTime.now().zoneName || 'UTC') =>
    post<{ [id: number]: ChartResult[] }>(`${Urls.fleet}/charts/systems/by-id`, { systems, charts, timezone })
};

export type ChartDTO = {
  metric: 'energy' | 'power' | 'storageEnergy' | 'storagePower';
  kind:
    | 'points' // no aggregation = one line per system * per timestamp from given interval
    | 'totalSystem' // aggregate over time = one line for each system
    | 'totalTime' // aggregate over systems = one line for each timestamp from given interval
    | 'total'; // aggregate over both time and systems = single value
  // Postgres interval syntax, see https://www.postgresql.org/docs/current/datatype-datetime.html#DATATYPE-INTERVAL-INPUT
  period: string;
  meter?: Meter;
  startAt?: Date | string;
  endBefore?: Date | string;
};

export type ChartResult =
  // kind=points
  | SystemEnergy[]
  | SystemPower[]
  | SystemStorageEnergy[]
  | SystemStoragePower[]
  // kind=totalSystem
  | SystemTotalEnergy[]
  | SystemTotalPower[]
  | SystemTotalStorageEnergy[]
  | SystemTotalStoragePower[]
  // kind=totalTime
  | TimeTotalEnergy[]
  | TimeTotalPower[]
  | TimeTotalStorageEnergy[]
  | TimeTotalStoragePower[]
  // kind=total
  | TotalEnergy
  | TotalPower
  | TotalStorageEnergy
  | TotalStoragePower;

export enum Meter {
  Consumption = 'CONSUMPTION',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Production = 'PRODUCTION'
}

export type SystemEnergy = {
  system: string;
  // SQL datetime
  time: string;
  value: number;
};

export type SystemPower = {
  system: string;
  // SQL datetime
  time: string;
  value: number;
};

export type SystemStorageEnergy = {
  system: string;
  // SQL datetime
  time: string;
  charge: number;
  discharge: number;
};

export type SystemStoragePower = {
  system: string;
  // SQL datetime
  time: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type SystemTotalEnergy = {
  system: string;
  value: number;
};

export type SystemTotalPower = {
  system: string;
  value: number;
};

export type SystemTotalStorageEnergy = {
  system: string;
  charge: number;
  discharge: number;
};

export type SystemTotalStoragePower = {
  system: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type TimeTotalEnergy = {
  // SQL datetime
  time: string;
  value: number;
};

export type TimeTotalPower = {
  // SQL datetime
  time: string;
  value: number;
};

export type TimeTotalStorageEnergy = {
  // SQL datetime
  time: string;
  charge: number;
  discharge: number;
};

export type TimeTotalStoragePower = {
  // SQL datetime
  time: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type TotalEnergy = {
  value: number;
};

export type TotalPower = {
  value: number;
};

export type TotalStorageEnergy = {
  charge: number;
  discharge: number;
};

export type TotalStoragePower = {
  charge: number;
  discharge: number;
  percent: number;
};
