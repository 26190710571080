import React, { useCallback, useState } from 'react';
import { COLUMNS, COLUMNS_BY_ID, renderColumnPlain, SystemColumn } from '@features/SystemPortfolio/constants';
import { InfoIcon } from '@kit/ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import { Home } from 'react-feather';
import { RichTextField, useForm } from '@kit/components/Form';
import { useUpdateSystem } from '@hooks/systems/useUpdateSystem';
import { System } from '@generated/types/graphql';
import { Address } from '@kit/ui/anchors/Address';
import { CopyButton } from '@common/ui';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { AccountType } from '@types';
import { BuildingIcon } from '@kit/ui/icons/Building';
import { capitalize } from 'lodash';
import { formatPower } from '@features/SystemPortfolio/utils';
import { isQuilValueEmpty } from '@utils/quill';
import { useUpdateEffect } from '@react-hookz/web';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { useModal } from '@common/PromiseModal';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import {
  Chips,
  Copyable,
  EditableWrapper,
  PrimaryContactBlock,
  ProjectContact,
  ProjectContacts,
  PropertiesGrid,
  PropertyContainer,
  PropertyName,
  PropertyValue,
  ValuePlaceholder
} from './styled';
import { FleetEditableChip } from '../../FleetEditableChip';

interface Props {
  system: System;
}

const HIDDEN_COLUMNS = [
  SystemColumn.address,
  SystemColumn.size,
  SystemColumn.notes,
  SystemColumn.clientType,
  SystemColumn.profile,
  SystemColumn.sevenDayAverage
];

export const NormalizedProperties = ({ system }: Props) => {
  const regularColumns = COLUMNS.filter((column) => !column.stats && !HIDDEN_COLUMNS.includes(column.id));

  const notesColumn = COLUMNS_BY_ID[SystemColumn.notes];

  const { mutateAsync: update } = useUpdateSystem();
  const { openModal } = useModal();

  const [showNotesEditor, setShowNotesEditor] = useState(false);

  const toggleNotesEditor = useCallback(() => {
    setShowNotesEditor((prev) => !prev);
  }, []);

  const {
    form: { control, reset },
    handleSubmit
  } = useForm<{ notes: string }>({
    onSubmit: async (values) => {
      await update({ id: system.id, dto: { notes: values.notes } });

      setShowNotesEditor(false);
    },
    defaultValues: {
      notes: system.notes
    }
  });

  const [primaryContact] = system.project?.projectContacts ?? [];

  const handleEditPrimaryContact = useCallback(() => {
    openModal<void>(({ onClose }) => <ContactForm initialValues={primaryContact} onClose={onClose} />, {
      title: 'Edit Contact'
    });
  }, [primaryContact, openModal]);

  useUpdateEffect(() => {
    reset({ notes: system.notes });
  }, [system.notes]);

  const address = system.addressForced ?? system.address;

  return (
    <Widget size="large" isFullWidth>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#DFDFE8">
            <InfoIcon size="16px" color="#737382" />
          </WidgetIcon>
          Properties
        </WidgetTitle>
      </WidgetHeader>

      <div>
        {Boolean(address) && (
          <Copyable>
            <Address value={address} />
            <CopyButton value={address} />
          </Copyable>
        )}

        <Chips>
          {system.project && (
            <Badge bgColor="#E4E8EC" color="#1D1D35" size={BadgeSize.Large}>
              {system.project.accountType === AccountType.RESIDENTIAL ? (
                <Home color="#9C9CAA" size="16px" />
              ) : (
                <BuildingIcon color="#9C9CAA" size="16px" />
              )}
              {capitalize(system.project.accountType)}
            </Badge>
          )}

          <Badge bgColor="#E4E8EC" color="#1D1D35" size={BadgeSize.Large}>
            {system.size === null ? '- kW' : formatPower(system.size)}
          </Badge>

          <FleetEditableChip systemId={system.id} value={system.profile} />
        </Chips>
      </div>

      {primaryContact && (
        <PrimaryContactBlock>
          <div>
            <PropertyName>Primary contact</PropertyName>
          </div>

          <ProjectContacts>
            <EditableWrapper onClick={handleEditPrimaryContact}>
              <Copyable>
                <ProjectContact>
                  <ContactAvatar contact={primaryContact} />
                  <span>{primaryContact.name}</span>
                </ProjectContact>
                <CopyButton value={primaryContact.name} />
              </Copyable>
            </EditableWrapper>

            {Boolean(primaryContact.phones[0]) && (
              <Copyable>
                <PhoneNumber value={primaryContact.phones[0]} />
                <CopyButton value={primaryContact.phones[0]} />
              </Copyable>
            )}

            {Boolean(primaryContact.emails[0]) && (
              <Copyable>
                <EmailAddress value={primaryContact.emails[0]} />
                <CopyButton value={primaryContact.emails[0]} />
              </Copyable>
            )}

            {(!primaryContact.phones[0] || !primaryContact.emails[0]) && <div />}
          </ProjectContacts>
        </PrimaryContactBlock>
      )}

      <PropertiesGrid>
        <PropertyContainer fullWidth>
          <PropertyName>{notesColumn.name}</PropertyName>
          <PropertyValue isEditable>
            {showNotesEditor && <RichTextField name="notes" control={control} onBlur={handleSubmit} />}
            {!showNotesEditor && (
              <EditableWrapper onClick={toggleNotesEditor}>
                {isQuilValueEmpty(system.notes) && <ValuePlaceholder>-</ValuePlaceholder>}
                {!isQuilValueEmpty(system.notes) && <div dangerouslySetInnerHTML={{ __html: system.notes }} />}
              </EditableWrapper>
            )}
          </PropertyValue>
        </PropertyContainer>

        {regularColumns.map(({ id, name }) => (
          <PropertyContainer key={id}>
            <PropertyName>{name}</PropertyName>
            <PropertyValue>
              <Tooltip title={renderColumnPlain(system, id)}>
                <span>{renderColumnPlain(system, id)}</span>
              </Tooltip>
            </PropertyValue>
          </PropertyContainer>
        ))}
      </PropertiesGrid>
    </Widget>
  );
};
